@import '../../styles/customMediaQueries.css';

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;

  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #fff;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: block;
  flex: 0 1 0%;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 24px 0 0 24px; */
  }
  @media (--viewportLarge) {
    /* padding: 40px 0 0 36px; */

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: var(--colorWhite);
  }

  /* @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  } */
}

.filterColumnContent {
  width: 155px;
  /* padding: 8px 24px 0 0; */

  /* @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  } */

  /* @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  } */

  /* @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  } */
}

.filter {
  flex-basis: 30%;

  @media (--viewportLarge) {
    flex-basis: 19%;
  }
}

.filter:last-child {
  margin-left: 5%;
  margin-right: auto;

  @media (--viewportLarge) {
    margin: 0;
  }

  @media (max-width: 658px) {
    margin: 0;
  }
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

/* TODO: */
.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  flex: 1 1 0%;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    /* padding: 24px 24px 0 24px; */
    background-color: var(--colorWhite);
    /* border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100); */
    padding: 20px 24px 0;
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    /* padding: 20px 36px 0 36px; */
    padding: 20px 24px 0;
  }

  @media (min-width: 1440px) {
    /* padding: 20px calc((100vw - 1368px) / 2) 0 59px; */
    padding: 20px 0 0;
  }
  @media (--viewportXLarge) {
    /* padding: 20px calc((100vw - 1800px) / 2) 0 59px; */
    padding: 20px 0 0;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  margin-top: 20px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

/* TODO: */
.searchFiltersMobileList {
  padding: 16px 0;

  display: none !important;

  /* @media (--viewportMedium) {
    display: none;
  } */
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.toggleModalBtn {
  padding: 12px 14px;
  border: 1px solid rgba(145, 145, 214, 1);
  border-radius: 30px;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  line-height: 22px;
  background: transparent;
  font-family: var(--fontFamily);
  width: 100%;
  height: 46px;
  min-height: 46px;
  text-align: center;

  &:hover {
    color: rgba(0, 0, 0, 1);
    background: transparent;
    box-shadow: none;
  }

  @media (min-width: 658px) {
    width: 300px;
  }

  @media (--viewportMedium) {
    text-align: left;
  }
}

.filtersModalContainerClassName {
  flex-basis: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  /* height: 100%; */
  background-color: #fff;

  min-height: 100vh;
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 658px) {
    flex-direction: row;
    padding: 0;
  }
}

.btnsWrapper {
  display: flex;
  justify-content: center;
  padding: 0 20px 20px;
  margin-top: 20px;

  @media (min-width: 658px) {
    margin-top: 40px;
    padding: 0 0 20px;
  }
}

.btnClear {
  width: 160px;
  padding: 8px 32px;
  margin-right: 20px;
  height: 46px;
  min-height: 46px;
  color: rgba(0, 0, 0, 1);
  border-radius: 30px;
  background-color: transparent;
  border: 1.5px solid rgba(145, 145, 214, 1);
  font-size: 20px;

  &:hover {
    background-color: rgba(145, 145, 214, 1);
    box-shadow: none;
    color: #000;
  }
}

.btnDone {
  width: 160px;
  padding: 8px 32px;
  height: 46px;
  min-height: 46px;
  color: rgba(0, 0, 0, 1);
  border-radius: 30px;
  font-size: 20px;
  background-color: transparent;
  border: 1.5px solid rgba(145, 145, 214, 1);

  &:hover {
    box-shadow: none;
    color: #000;
    background-color: rgba(145, 145, 214, 1);
  }
}

.filterModalWrapper {
  width: 48%;

  @media (min-width: 658px) {
    width: auto;
  }
}

.closeFilterWrapper {
  display: flex;
  justify-content: space-between;
  background-color: rgba(252, 249, 244, 1);
  padding: 100px 16px 16px;

  & span {
    font-family: var(--fontFamily);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }

  & button {
    border: none;
    outline: none;
    cursor: pointer;
  }

  @media (min-width: 658px) {
    display: none;
  }
}
