@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.listingTypeBtn {
  border-radius: 30px;
  border: 1.5px solid #1b1b1b;
  height: 46px;
  min-height: 46px;
  flex: 1;
  font-size: 20px;
}

.productsBtn {
  border-color: #ffcb47;

  &:focus {
    border-color: #ffcb47;
  }
  &:hover {
    background-color: #ffcb47;
    border-color: #ffcb47;
    box-shadow: none;
  }
}
.trainingsBtn {
  border-color: #ffaeab;

  &:focus {
    border-color: #ffaeab;
  }
  &:hover {
    background-color: #ffaeab;
    border-color: #ffaeab;
    box-shadow: none;
  }
}
.consultationsBtn {
  border-color: #ccebe5;

  &:focus {
    border-color: #ccebe5;
  }
  &:hover {
    background-color: #ccebe5;
    border-color: #ccebe5;
    box-shadow: none;
  }
}

.productsBtnActive {
  background-color: #ffcb47;

  &:focus {
    background-color: #ffcb47;
  }
}
.trainingsBtnActive {
  background-color: #ffaeab;

  &:focus {
    background-color: #ffaeab;
  }
}
.consultationsBtnActive {
  background-color: #ccebe5;

  &:focus {
    background-color: #ccebe5;
  }
}
