@import '../../../styles/customMediaQueries.css';

.root {
}

.filterModalWrapper {
  /* width: 48%; */

  width: auto;

  @media (min-width: 658px) {
    width: auto;
  }
}

.toggleModalBtn {
  padding: 12px 14px;
  border: 1px solid rgba(145, 145, 214, 1);
  border-radius: 30px;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  line-height: 22px;
  background: transparent;
  font-family: var(--fontFamily);
  width: 100%;
  height: 46px;
  min-height: 46px;
  text-align: center;

  &:hover {
    color: rgba(0, 0, 0, 1);
    background: transparent;
    box-shadow: none;
  }

  @media (min-width: 658px) {
    width: 300px;
  }
}

.filtersModalContainerClassName {
  flex-basis: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.closeFilterWrapper {
  display: flex;
  justify-content: space-between;
  background-color: rgba(252, 249, 244, 1);
  padding: 75px 16px 16px;

  & span {
    font-family: var(--fontFamily);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }

  & button {
    border: none;
    outline: none;
    cursor: pointer;
  }

  @media (min-width: 658px) {
    display: none;
  }
}

.listItem:last-of-type {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(145, 145, 214, 1);
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;
  /* border-bottom: 1px solid rgba(145, 145, 214, 1); */

  @media (min-width: 658px) {
    flex-direction: row;
    padding: 0;
  }
}

.btnsWrapper {
  display: flex;
  justify-content: center;
  padding: 0 20px 20px;

  @media (min-width: 658px) {
    padding: 0;
  }
}

.btnDone {
  width: 160px;
  padding: 8px 32px;
  height: 46px;
  min-height: 46px;
  color: rgba(0, 0, 0, 1);
  border-radius: 30px;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  line-height: 2.4;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--colorGrey300);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--colorBlack);
}

.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

.btnApply {
  height: 46px;
  min-height: 46px;
  border: 1.5px solid rgba(145, 145, 214, 1);
  border-radius: 30px;
  padding: 8px 32px;
  color: #fff;
}

.content {
}

.btnsWrapper {
  margin-top: 20px;
}

.btnSortBy {
  background-color: transparent;
  color: #000;
  border: 1.5px solid rgba(145, 145, 214, 1);
  border-radius: 30px;
  height: 46px;
  min-height: 46px;
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnSortByWrapper {
  margin-top: 10px;
  padding: 0 24px;
}
