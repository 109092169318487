@import '../../../styles/customMediaQueries.css';

.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  margin-left: 0;

  @media (min-width: 658px) {
    margin-left: auto;
  }
}

.sortByMenuLabel {
  /* display: inline-block; */
  width: 100%;
  height: 46px;
  min-height: 46px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 14px;
  margin: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #000000;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid #9191d6;
  border-radius: 30px;

  background-color: var(--colorWhite);

  /* &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
    border-color: var(--colorGrey300);
  } */

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    /* height: auto; */
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 20px;

    /* &:hover,
    &:focus {
      border-color: transparent;
    } */
  }

  @media (min-width: 658px) {
    width: 300px;
  }
}
